<template>
  <div id="defaultId1">
    <div class="pb20">
        <!-- <div c> -->


        <div id="cc5f49" >
          <div class="right_cont_box  company_box">
            <div class="table-index">
              <h2 class="operations-head ">
          <span class="head-titel detail_title_20">Sanction List</span>
          <!-- <el-select  style="width: 14.28rem;margin-left: 40px;"  v-model="Importyear" placeholder="Please Select" @change="ImportYear">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
        </h2>
              <div class=" operations-box-bottom table_X company_detail">
                <el-table :data="ImporttableData" style="width: 100%" :key="key" empty-text="No Data">
                  <el-table-column prop="goodsCodes1" width="160" label="HScode" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.goodsCodes1 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="regulationShortCode" label="Management regulations" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.regulationShortCode
                        }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="regionRestrictionNames" label="Controlled countries" align="center" show-overflow-tooltip>

                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.regionRestrictionNames }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="regionRestrictionType" label="Control type " show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.regionRestrictionType }}</span>
                    </template>
                  </el-table-column>




                  <el-table-column prop="itemDescription" width="230" label="product description" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span class="table-content company_link">{{ scope.row.itemDescription }}</span>
                    </template>
                  </el-table-column>




                </el-table>
                <div style="text-align: center; padding-top: 20px">
                  <span class="contclass show_All">Show All</span>
                </div>
              </div>

            </div>

          </div>

        </div>
        <!-- </div> -->




    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>

  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getProductChainSanctions
} from "@/api/companyApi";
import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import { mixins1 } from '@/mixins/index';
import ReLogin from "../../../../components/ReLogin.vue";
import { searchCompany, productSearch, searchTradeHscode } from "@/api/searchApi.js";
//importEnd

export default {
  name: "",
  mixins: [mixins1],
  props: {},

  components: {
    gui_card,
    rectcard,
    content_text,

    ReLogin,
  },
  computed: {},
  created() {

  },
  mounted() {
    this.$store.dispatch("app/toggleMenu", false);
    this.$emit("UpdatamarkTotalList", []);
    this.getCompanyList()
  },
  data() {
    return {
      reLoginVis: false,
      Importyear: '2022',
      key: 0,
      Related: false,
      value: {},
      content: [],
      pageSize: 10,
      page: 1,
      ImporttableData: [],
      hscode: '',
      Yearoptions: [
        {
          label: '2025',
          value: '2025',
        },
        {
          label: '2024',
          value: '2024',
        },
        {
          label: '2023',
          value: '2023',
        },
        {
          label: '2022',
          value: '2022',
        },
        {
          label: '2021',
          value: '2021',
        },
        {
          label: '2020',
          value: '2020',
        },
        {
          label: '2019',
          value: '2019',
        },
        {
          label: '2018',
          value: '2018',
        },
        {
          label: '2017',
          value: '2017',
        },
        {
          label: '2016',
          value: '2016',
        },
        {
          label: '2015',
          value: '2015',
        },
        {
          label: '2014',
          value: '2014',
        },
        {
          label: '2013',
          value: '2013',
        },

      ],


      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  computed: {



  },
  methods: {
    // ImportYear(){
    // },
    getCompanyList() {
      getProductChainSanctions({
        hsCode: this.$route.query.id3a,

        page: this.page,
        pageSize: this.pageSize

      }).then(res => {
        if (res.code == 200) {
          this.ImporttableData = res.data.rows
        } else {
          this.ImporttableData = []
        }

      })
    },
    scrollToTop() {
      if (this.parentTablelist.length > 0) {
        // this.Related = true;
      } else {
      }

      this.Related = true

    },


    // 获取数据

    default() { },
    // srcviewscompanydetailsBackgroundSnapshotvueMethodEnd
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #238ACC;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
}

.ViewMore {
  line-height: 1.8571428571428572rem;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;

}

.operations-head {
  height: 2.857142857142857rem;
  font-family: 'DMSans Bold';
  margin-bottom: 2.142857142857143rem;
  justify-content: flex-start;

}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-left {
  display: flex;
  font-size: 1.1428571428571428rem;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #022955;
  padding-left: 1.4285714285714286rem;
  background-color: #f2f5f9;
}

.footer {
  padding-left: 0rem;
  overflow: hidden;
}

.ecahrts-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.07142857142857142rem solid #cccccc;
}

.title-left {
  display: flex;
  align-items: center;
  padding-bottom: 0.9285714285714286rem;
  padding-top: 0.9285714285714286rem;
}

.td {
  margin: 1.0714285714285714rem 0 0 1.7857142857142858rem;
}

.relate-left {
  height: 4.142857142857143rem;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 4.142857142857143rem;
  text-align: left;
  font-family: 'DMSans Bold';
}

.relate-Financial {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-size: Arial;
}

.computer_right_cont_box {
  margin-top: 1.4285714285714286rem;
}

.right_cont_box {
  padding: 2.142857142857143rem 2.857142857142857rem;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0em 0.14285714285714285em 1em 0em rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}


.tablewieth {
  width: 70%;
}

.aaa {
  font-size: 0.7142857142857143rem;
  color: #fa6666;
  position: absolute;
  left: 2.142857142857143rem;
  top: 14.285714285714286rem;
}

.bbb {
  font-size: 0.7142857142857143rem;
  color: #fa755c;
  position: absolute;
  left: 2.5rem;
  top: 6.071428571428571rem;
}

.ccc {
  font-size: 0.7142857142857143rem;
  color: #fc9249;
  position: absolute;
  left: 7.5rem;
  top: 0.5714285714285714rem;
}

.ddd {
  font-size: 0.7142857142857143rem;
  color: #ffd81a;
  position: absolute;
  left: 23.214285714285715rem;
  top: 0.5714285714285714rem;
}

.fff {
  font-size: 0.7142857142857143rem;
  color: #aec918;
  position: absolute;
  width: 3.642857142857143rem !important;
  height: 2.142857142857143rem;
  top: 6.071428571428571rem;
  left: 30rem;
}

.ggg {
  font-size: 0.7142857142857143rem;
  color: #63b819;
  position: absolute;
  left: 28.928571428571427rem;

  top: 14.285714285714286rem;
}

.hhh {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  position: absolute;
  left: 13.428571428571429rem;
  top: 14.285714285714286rem;
}

.echarts {
  width: 30.714285714285715rem;
  height: 17.857142857142858rem;
  padding-left: 3.2857142857142856rem;
}

.table_box {
  position: relative;
}

.echarts-content {
  margin-top: 2.857142857142857rem;
  padding: 0 4.285714285714286rem;
}

.echarts-item {
  display: flex;
  justify-content: flex-start;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  margin-bottom: 2.142857142857143rem;
}

.echarts-item-left {
  display: inline-block;
  width: 15.714285714285714rem;
  margin-right: 1.4285714285714286rem;
  font-family: 'DMSans Bold';
}

.ecahrtstutwo {
  position: relative;
  left: 1.4285714285714286rem;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.left_box {
  width: 91%;

}

.right_box {
  width: 40%;
  background-color: #fff;
}

.operations-box-bottom /deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  padding-left: 0.7142857142857143rem !important;
}

/* 贸易关单 */
.table_oper1 {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 27px;
}

.ant-modal-body {
  padding: 24px;
  font-size: 1rem;
  color: #1A2332;
  line-height: 1.5;
  word-wrap: break-word;
}

.trade-details-field {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.trade-details-modal table {
  width: 100%;
  margin-bottom: 18px;
  font-size: 1rem;
}

.trade-details-modal table,
.trade-details-modal td,
.trade-details-modal th {
  /* border: 1px solid #ddd; */
  padding: 0.55rem;
  border-collapse: collapse;
  word-break: keep-all;
  text-align: right;
}

.trade-details-modal table thead {
  background: #009EA7;
  color: #fff;
}

.trade-details-modal table thead tr>th {
  /* color: #000; */
  font-weight: 700;
  padding-left: 12px;
  text-align: left;
}

.trade-details-modal th {
  /* border: 1px solid #ddd; */
  padding: 0.55rem;
}

.button-list-in-trade-detail {
  display: inline-block;
}

.button-list-in-trade-detail .row-content {
  display: inline-block;
  padding-right: 40px;
  vertical-align: top;
}

.button-list-in-trade-detail .link-button {
  display: inline-block;
  padding: 0 9px;
  color: #177dff;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.25s;
}

.trade-details-modal table tbody tr>td {
  color: rgba(0, 0, 0, 0.65);
  padding-left: 12px;
  text-align: left;
  line-height: 25px;
  /* word-break: break-all; */
}

.trade-details-modal td {
  /* border: 1px solid #ddd; */
  padding: 0.55rem;
}

.button-list-in-trade-detail .ant-divider-vertical {
  margin: 0 3px;
}

.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}

.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #e8e8e8;
}

.line_11 {
  color: rgb(18, 144, 201);
  text-decoration: underline;
  cursor: pointer;
}

.back {
  background-color: #f1f3f4;
}

.trade-details-modal tr {
  border: 1px solid #ddd;
  border-right: none;
  border-left: none;
}

.companyimg {
  float: left;
  width: 36px;
  height: 26px;
  flex: none;
  margin-right: 4px;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
}

.left_menu {

  background-color: #fff;
  max-height: 95%;
  overflow-y: auto;
  height: auto;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 2.142857142857143rem 1.42857142857143rem;

}

.hidemenu {
  display: block;
  width: 0em;
  padding-left: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  padding-right: 0em;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
}

.udb507 {
  width: calc(100% - 25em);
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding-left: 0em;
  margin-left: 0.7142857142857143em;
}

.udb507two {
  width: 100% !important;
}

.left_menu_context-title {
  font-size: 1.28571429rem;
  color: #1A2332;
  font-family: 'DMSans Bold';
  margin-bottom: 1.4285714285714286rem;
}

.left_menu_context {
  padding-bottom: 1.4285714285714286rem;
  margin-bottom: 1.4285714285714286rem;
  border-bottom: 1px solid #CCCCCC;
}

.el-range-editor.el-input__inner {
  padding: 0px !important;
}

.left_menu_context /deep/ .el-input__inner {
  height: 36px !important;
}

/deep/ .el-radio {
  justify-content: flex-start;
  font-size: 1.1428575rem !important;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #009EA7;
  font-size: 1.1428575rem !important;
}

/deep/ .el-radio__label {
  font-size: 1.1428575rem !important;
  color: #1a2332;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #009EA7;
  background: #fff;
}

/deep/ .el-radio__input.is-checked .el-radio__inner::after {
  background: #009EA7;
}
</style>
